

/* Section/Article */

section.special, article.special {
  text-align: center;
}

header.major {
  margin-bottom: 3em;
}

  header.major h2 {
    font-size: 2em;
  }

    header.major h2:after {
      display: block;
      content: '';
      width: 3.25em;
      height: 2px;
      margin: 0.7em 0 1em 0;
      border-radius: 2px;
    }

      section.special header.major h2:after, article.special header.major h2:after {
        margin-left: auto;
        margin-right: auto;
      }

  header.major p {
    font-size: 1.25em;
    letter-spacing: -0.025em;
  }

  header.major.special {
    text-align: center;
  }

    header.major.special h2:after {
      margin-left: auto;
      margin-right: auto;
    }

footer.major {
  margin-top: 3em;
}

@media screen and (max-width: 736px) {

  header.major {
    margin-bottom: 0;
  }

    header.major h2 {
      font-size: 1.5em;
    }

    header.major p {
      font-size: 1em;
      letter-spacing: 0;
    }

      header.major p br {
        display: none;
      }

  footer.major {
    margin-top: 0;
  }

}

header.major h2:after {
  background-color: rgba(255, 255, 255, 0.35);
}

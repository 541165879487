/* Type */

body {
    background-color: #6667ab;
    color: rgba(255, 255, 255, 0.65);
}

body,
input,
select,
textarea {
    font-family: "Source Sans Pro", Helvetica, sans-serif;
    font-size: 17pt;
    font-weight: 300;
    line-height: 1.65;
}

@media screen and (max-width: 1680px) {
    body,
    input,
    select,
    textarea {
        font-size: 14pt;
    }
}

@media screen and (max-width: 1280px) {
    body,
    input,
    select,
    textarea {
        font-size: 12pt;
    }
}

@media screen and (max-width: 360px) {
    body,
    input,
    select,
    textarea {
        font-size: 11pt;
    }
}

a {
    -moz-transition: color 0.2s ease, border-bottom 0.2s ease;
    -webkit-transition: color 0.2s ease, border-bottom 0.2s ease;
    -ms-transition: color 0.2s ease, border-bottom 0.2s ease;
    transition: color 0.2s ease, border-bottom 0.2s ease;
    text-decoration: none;
    border-bottom: dotted 1px;
    color: inherit;
}

a:hover {
    border-bottom-color: transparent;
}

strong,
b {
    font-weight: 400;
}

em,
i {
    font-style: italic;
}

p {
    margin: 0 0 2em 0;
}

p.content {
    -moz-columns: 20em 2;
    -webkit-columns: 20em 2;
    -ms-columns: 20em 2;
    columns: 20em 2;
    -moz-column-gap: 2em;
    -webkit-column-gap: 2em;
    -ms-column-gap: 2em;
    column-gap: 2em;
    text-align: justify;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 300;
    line-height: 1.5;
    margin: 0 0 0.7em 0;
    letter-spacing: -0.025em;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
    color: inherit;
    text-decoration: none;
}

h1 {
    font-size: 2.5em;
    line-height: 1.2;
}

h2 {
    font-size: 1.5em;
}

h3 {
    font-size: 1.25em;
}

h4 {
    font-size: 1.1em;
}

h5 {
    font-size: 0.9em;
}

h6 {
    font-size: 0.7em;
}

@media screen and (max-width: 736px) {
    h1 {
        font-size: 2em;
    }
}

sub {
    font-size: 0.8em;
    position: relative;
    top: 0.5em;
}

sup {
    font-size: 0.8em;
    position: relative;
    top: -0.5em;
}

blockquote {
    border-left: solid 4px;
    font-style: italic;
    margin: 0 0 2em 0;
    padding: 0.5em 0 0.5em 2em;
}

code {
    border-radius: 8px;
    border: solid 1px;
    font-family: "Courier New", monospace;
    font-size: 0.9em;
    margin: 0 0.25em;
    padding: 0.25em 0.65em;
}

pre {
    -webkit-overflow-scrolling: touch;
    font-family: "Courier New", monospace;
    font-size: 0.9em;
    margin: 0 0 2em 0;
}

pre code {
    display: block;
    line-height: 1.75;
    padding: 1em 1.5em;
    overflow-x: auto;
}

hr {
    border: 0;
    border-bottom: solid 1px;
    margin: 2em 0;
}

hr.major {
    margin: 3em 0;
}

.align-left {
    text-align: left;
}

.align-center {
    text-align: center;
}

.align-right {
    text-align: right;
}

input,
select,
textarea {
    color: #ffffff;
}

a:hover {
    color: #ffffff;
}

strong,
b {
    color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #ffffff;
}

blockquote {
    border-left-color: rgba(255, 255, 255, 0.35);
}

code {
    background: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.35);
}

hr {
    border-bottom-color: rgba(255, 255, 255, 0.35);
}

/* Button */

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  -moz-appearance: none;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  -moz-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  -ms-transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-weight: 300;
  height: 2.75em;
  line-height: 2.75em;
  min-width: 9.25em;
  padding: 0 1.5em;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
}

  input[type="submit"].icon,
  input[type="reset"].icon,
  input[type="button"].icon,
  button.icon,
  .button.icon {
    padding-left: 1.35em;
  }

    input[type="submit"].icon:before,
    input[type="reset"].icon:before,
    input[type="button"].icon:before,
    button.icon:before,
    .button.icon:before {
      margin-right: 0.5em;
    }

  input[type="submit"].fit,
  input[type="reset"].fit,
  input[type="button"].fit,
  button.fit,
  .button.fit {
    width: 100%;
  }

  input[type="submit"].small,
  input[type="reset"].small,
  input[type="button"].small,
  button.small,
  .button.small {
    font-size: 0.8em;
  }

  input[type="submit"].large,
  input[type="reset"].large,
  input[type="button"].large,
  button.large,
  .button.large {
    font-size: 1.35em;
  }

  input[type="submit"].disabled, input[type="submit"]:disabled,
  input[type="reset"].disabled,
  input[type="reset"]:disabled,
  input[type="button"].disabled,
  input[type="button"]:disabled,
  button.disabled,
  button:disabled,
  .button.disabled,
  .button:disabled {
    pointer-events: none;
    opacity: 0.25;
  }

  @media screen and (max-width: 736px) {

    input[type="submit"],
    input[type="reset"],
    input[type="button"],
    button,
    .button {
      min-width: 0;
    }

  }

input[type="submit"],
input[type="reset"],
input[type="button"],
button,
.button {
  background-color: transparent;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.35);
  color: #ffffff !important;
}

  input[type="submit"]:hover,
  input[type="reset"]:hover,
  input[type="button"]:hover,
  button:hover,
  .button:hover {
    background-color: rgba(255, 255, 255, 0.075);
  }

  input[type="submit"]:active,
  input[type="reset"]:active,
  input[type="button"]:active,
  button:active,
  .button:active {
    background-color: rgba(255, 255, 255, 0.2);
  }

  input[type="submit"].icon:before,
  input[type="reset"].icon:before,
  input[type="button"].icon:before,
  button.icon:before,
  .button.icon:before {
    color: rgba(255, 255, 255, 0.5);
  }

  input[type="submit"].primary,
  input[type="reset"].primary,
  input[type="button"].primary,
  button.primary,
  .button.primary {
    background-color: #8cc9f0;
    color: #ffffff !important;
    box-shadow: none;
  }

    input[type="submit"].primary:hover,
    input[type="reset"].primary:hover,
    input[type="button"].primary:hover,
    button.primary:hover,
    .button.primary:hover {
      background-color: #9acff2;
    }

    input[type="submit"].primary:active,
    input[type="reset"].primary:active,
    input[type="button"].primary:active,
    button.primary:active,
    .button.primary:active {
      background-color: #7ec3ee;
    }

    input[type="submit"].primary.icon:before,
    input[type="reset"].primary.icon:before,
    input[type="button"].primary.icon:before,
    button.primary.icon:before,
    .button.primary.icon:before {
      color: #ffffff !important;
    }

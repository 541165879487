/* List */

ol {
    list-style: decimal;
    margin: 0 0 2em 0;
    padding-left: 1.25em;
}

ol li {
    padding-left: 0.25em;
}

ul {
    list-style: disc;
    margin: 0 0 2em 0;
    padding-left: 1em;
}

ul li {
    padding-left: 0.5em;
}

dl {
    margin: 0 0 2em 0;
}

dl dt {
    display: block;
    font-weight: 400;
    margin: 0 0 1em 0;
}

dl dd {
    margin-left: 2em;
}

dl.alt dt {
    display: block;
    width: 3em;
    margin: 0;
    clear: left;
    float: left;
}

dl.alt dd {
    margin: 0 0 0.85em 5.5em;
}

dl.alt:after {
    content: '';
    display: block;
    clear: both;
}

ul.alt li {
    border-top-color: rgba(255, 255, 255, 0.35);
}

dl dt {
    color: #ffffff;
}
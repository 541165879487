video {
  width: 1200px;
}
@media screen and (max-width: 1700px) {
  video {
      width: 1000px;
  }
}
@media screen and (max-width: 1280px) {
  video {
      width: 800px;
  }
}
@media screen and (max-width: 1000px) {
  video {
      width: 600px;
  }
}
@media screen and (max-width: 720px) {
  video {
      width: 500px;
  }
}
@media screen and (max-width: 600px) {
  video {
      width: 300px;
  }
}
/* Features */

.features {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -moz-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  width: calc(100% + 2em);
  margin: 0 0 3em -2em;
  padding: 0;
  list-style: none;
}

  .features li {
    width: calc(33.33333% - 2em);
    margin-left: 2em;
    margin-top: 3em;
    padding: 0;
  }

    .features li:nth-child(1), .features li:nth-child(2), .features li:nth-child(3) {
      margin-top: 0;
    }

    .features li > :last-child {
      margin-bottom: 0;
    }

  @media screen and (max-width: 980px) {

    .features li {
      width: calc(50% - 2em);
    }

      .features li:nth-child(3) {
        margin-top: 3em;
      }

  }

  @media screen and (max-width: 736px) {

    .features {
      width: 100%;
      margin: 0 0 2em 0;
    }

      .features li {
        width: 100%;
        margin-left: 0;
        margin-top: 2em;
      }

        .features li:nth-child(2), .features li:nth-child(3) {
          margin-top: 2em;
        }

  }

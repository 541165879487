@import url(dependancesCss/button.css);
@import url(dependancesCss/footer.css);
@import url(dependancesCss/icon.css);
@import url(dependancesCss/list.css);
@import url(dependancesCss/nav.css);
@import url(dependancesCss/sectionarticle.css);
@import url(dependancesCss/wrapper.css);
@import url(dependancesCss/initialisation.css);
@import url(dependancesCss/basic.css);
@import url(dependancesCss/features.css);
@import url(dependancesCss/basic.css);
@import url(dependancesCss/header.css);
@import url(dependancesCss/type.css);
@import url(dependancesCss/video.css);

/*
	Stellar by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

@font-face {
    font-family: "Source Sans Pro";
    src: url(../webfonts/SourceSansPro-Regular.woff) format('woff'), url(../webfonts/SourceSansPro-Regular.ttf) format('truetype');
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: "Source Sans Pro";
    src: url(../webfonts/SourceSansPro-Light.woff) format('woff'), url(../webfonts/SourceSansPro-Light.ttf) format('truetype');
    font-weight: 300;
    font-display: swap;
}
.hidden {
    display: none;
}

.content {
    display: flex;
}

.content .image {
    margin: auto;
}

.content .image-small {
    width: 295px;
    height: 820px;
    margin: auto;
    display: none;
    border: 4px solid black;
    border-radius: 5px;
}


/* Si pas assez large on mets les élements les uns sur les autres  */

@media screen and (max-width: 1280px) {
    .content {
        display: block;
    }
    .content .image {
        display: block;
    }
}


/* Etape suivante : on supprime l'image */

@media screen and (max-width: 720px) {
    .content .image {
        display: none;
    }
    /* Et on fait apparaitre la petite */
    .content .image-small {
        display: block;
    }
}


/* Main */

#main {
    background-color: #ffffff;
    color: #636363;
    border-radius: 0.25em;
}

#main input,
#main select,
#main textarea {
    color: #636363;
}

#main a:hover {
    color: #636363;
}

#main strong,
#main b {
    color: #636363;
}

#main h1,
#main h2,
#main h3,
#main h4,
#main h5,
#main h6 {
    color: #636363;
}

#main blockquote {
    border-left-color: #dddddd;
}

#main code {
    background: rgba(222, 222, 222, 0.25);
    border-color: #dddddd;
}

#main hr {
    border-bottom-color: #dddddd;
}

#main .box {
    border-color: #dddddd;
}

#main label {
    color: #636363;
}

#main input[type="text"],
#main input[type="password"],
#main input[type="email"],
#main select,
#main textarea {
    background-color: rgba(222, 222, 222, 0.25);
    border-color: #dddddd;
}

#main input[type="text"]:focus,
#main input[type="password"]:focus,
#main input[type="email"]:focus,
#main select:focus,
#main textarea:focus {
    border-color: #8cc9f0;
    box-shadow: 0 0 0 1px #8cc9f0;
}

#main select {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' preserveAspectRatio='none' viewBox='0 0 40 40'%3E%3Cpath d='M9.4,12.3l10.4,10.4l10.4-10.4c0.2-0.2,0.5-0.4,0.9-0.4c0.3,0,0.6,0.1,0.9,0.4l3.3,3.3c0.2,0.2,0.4,0.5,0.4,0.9 c0,0.4-0.1,0.6-0.4,0.9L20.7,31.9c-0.2,0.2-0.5,0.4-0.9,0.4c-0.3,0-0.6-0.1-0.9-0.4L4.3,17.3c-0.2-0.2-0.4-0.5-0.4-0.9 c0-0.4,0.1-0.6,0.4-0.9l3.3-3.3c0.2-0.2,0.5-0.4,0.9-0.4S9.1,12.1,9.4,12.3z' fill='%23dddddd' /%3E%3C/svg%3E");
}

#main select option {
    color: #636363;
    background: #ffffff;
}

#main input[type="checkbox"]+label,
#main input[type="radio"]+label {
    color: #636363;
}

#main input[type="checkbox"]+label:before,
#main input[type="radio"]+label:before {
    background: rgba(222, 222, 222, 0.25);
    border-color: #dddddd;
}

#main input[type="checkbox"]:checked+label:before,
#main input[type="radio"]:checked+label:before {
    background-color: #636363;
    border-color: #636363;
    color: #ffffff;
}

#main input[type="checkbox"]:focus+label:before,
#main input[type="radio"]:focus+label:before {
    border-color: #8cc9f0;
    box-shadow: 0 0 0 1px #8cc9f0;
}

#main ::-webkit-input-placeholder {
    color: rgba(99, 99, 99, 0.25) !important;
}

#main :-moz-placeholder {
    color: rgba(99, 99, 99, 0.25) !important;
}

#main ::-moz-placeholder {
    color: rgba(99, 99, 99, 0.25) !important;
}

#main :-ms-input-placeholder {
    color: rgba(99, 99, 99, 0.25) !important;
}

#main .formerize-placeholder {
    color: rgba(99, 99, 99, 0.25) !important;
}

#main .icon.major {
    border-color: #dddddd;
}

#main .icon.major:before {
    border-color: #dddddd;
}

#main .icon.alt {
    border-color: #dddddd;
    color: #636363;
}

#main .icon.alt:hover {
    background-color: rgba(222, 222, 222, 0.25);
}

#main .icon.alt:active {
    background-color: rgba(222, 222, 222, 0.5);
}

#main ul.alt li {
    border-top-color: #dddddd;
}

#main dl dt {
    color: #636363;
}

#main header.major h2:after {
    background-color: #dddddd;
    background-image: -moz-linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
    background-image: -webkit-linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
    background-image: -ms-linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
    background-image: linear-gradient(90deg, #efa8b0, #a89cc8, #8cc9f0);
}

#main table tbody tr {
    border-color: #dddddd;
}

#main table tbody tr:nth-child(2n+1) {
    background-color: rgba(222, 222, 222, 0.25);
}

#main table th {
    color: #636363;
}

#main table thead {
    border-bottom-color: #dddddd;
}

#main table tfoot {
    border-top-color: #dddddd;
}

#main table.alt tbody tr td {
    border-color: #dddddd;
}

#main .spotlight .image {
    border-color: #dddddd;
}

#main>.main {
    padding: 5em 5em 3em 5em;
    border-top: solid 1px #dddddd;
}

#main>.main:first-child {
    border-top: 0;
}

#main>.main>.image.main:first-child {
    margin: -5em 0 5em -5em;
    width: calc(100% + 10em);
    border-top-right-radius: 0.25em;
    border-top-left-radius: 0.25em;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

#main>.main>.image.main:first-child img {
    border-top-right-radius: 0.25em;
    border-top-left-radius: 0.25em;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

@media screen and (max-width: 1280px) {
    #main>.main {
        padding: 4em 4em 2em 4em;
    }
    #main>.main>.image.main:first-child {
        margin: -4em 0 4em -4em;
        width: calc(100% + 8em);
    }
}

@media screen and (max-width: 980px) {
    #main>.main {
        padding: 4em 3em 2em 3em;
    }
    #main>.main>.image.main:first-child {
        margin: -4em 0 4em -3em;
        width: calc(100% + 6em);
    }
}

@media screen and (max-width: 736px) {
    #main>.main {
        padding: 3em 2em 1em 2em;
    }
    #main>.main>.image.main:first-child {
        margin: -3em 0 2em -2em;
        width: calc(100% + 4em);
    }
}

@media screen and (max-width: 480px) {
    #main>.main {
        padding: 3em 1.5em 1em 1.5em;
    }
    #main>.main>.image.main:first-child {
        margin: -3em 0 1.5em -1.5em;
        width: calc(100% + 3em);
    }
}

@media screen and (max-width: 360px) {
    #main {
        border-radius: 0;
    }
    #main>.main {
        padding: 2.5em 1em 0.5em 1em;
    }
    #main>.main>.image.main:first-child {
        margin: -2.5em 0 1.5em -1em;
        width: calc(100% + 2em);
        border-radius: 0;
    }
    #main>.main>.image.main:first-child img {
        border-radius: 0;
    }
}
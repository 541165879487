/* Wrapper */

	#wrapper {
		width: 64em;
		max-width: calc(100% - 4em);
		margin: 0 auto;
	}

		@media screen and (max-width: 480px) {

			#wrapper {
				max-width: calc(100% - 2em);
			}

		}

		@media screen and (max-width: 360px) {

			#wrapper {
				max-width: 100%;
			}

		}
.icon.major {
    display: flex;
    margin: 0 auto 2.5em auto;
    border: 1px solid;
    border-radius: 100%;
    width: 225px;
    height: 225px;
    padding-left: 65px;
    padding-right: 65px;
}


/* Pour compatibilité safari  */

.icon.major img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1280px) {
    .icon.major {
        width: 195px;
        height: 195px;
        padding-left: 55px;
        padding-right: 55px;
    }
}

@media screen and (max-width: 736px) {
    .icon.major {
        width: 140px;
        height: 140px;
        padding-left: 40px;
        padding-right: 40px;
    }
}